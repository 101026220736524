import React from 'react';
import './footer.css';

function Footer() {
  return (
    <div className="footer">
      <h3>Copyright &copy;2024 Lauren Voigt Fine Art</h3>
    </div>
  );
}

export default Footer;
